import { Box, Typography, Tooltip, useMediaQuery } from '@mui/material'
import FavoriteIcon from '@/components/icons/favorite'
import React, { FC, useMemo } from 'react'
import Link from 'next/link'
import isFinite from 'lodash/isFinite'
import Image from 'next/image'
import { Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation'
import { ChatMessagePayload } from '@/interface/message'
import { getDetailPageUrlPath } from '@/lib/urlParse'
import usePropertyFavor from '@/hooks/usePropertyFavor'
import { LISTING_SERVICE } from '@/constants/listingService'
import { shareConfig } from '../../lib/catch'
import { getCurrency } from '../../lib/currency'
import LazyImg from '../img/lazyImg'
import { tagIconList } from '../../asset/listingData'
import Share from '../icons/share'
import Location from '../icons/location'
import { statsigLog } from '../../lib/statsigAndGa'
import Bed from '../icons/bed'
import Bath from '../icons/bath'
import Verify from '../icons/verify'

export interface HouseCardProps {
  allowTouchPicMove?: boolean
  property?: ChatMessagePayload['extraData']['propertyDetail']
  isMapMode?: boolean
  cardIndex?: number
  className?: string
  hiddenFavor?: boolean
  preLoadImg?: boolean // 部分场景房源图片不需要preload，比如首页，房源图片并不是lcp元素
  onMouseEnter?: (_data: ChatMessagePayload['extraData']['propertyDetail']) => void
  onMouseLeave?: (_data: ChatMessagePayload['extraData']['propertyDetail']) => void
  afterFavor?: (_data: ChatMessagePayload['extraData']['propertyDetail']) => void
  handleClick?: (_data: ChatMessagePayload['extraData']['propertyDetail']) => void
}

const HouseCard: FC<HouseCardProps> = ({
  allowTouchPicMove = true,
  property = {} as HouseCardProps['property'],
  isMapMode = false,
  hiddenFavor = false,
  cardIndex = 0,
  preLoadImg = true,
  className = 'w-[342px]',
  onMouseEnter,
  onMouseLeave,
  afterFavor = (_data: HouseCardProps['property']) => {},
  handleClick = (_data: HouseCardProps['property']) => {}
  // onClickSearch = () => {}
}) => {
  const {
    city,
    area,
    id,
    name,
    isFavor,
    availableContent,
    minPrice,
    marketingTag,
    availableStatus,
    address,
    picList,
    isVr,
    isVideo,
    minBd,
    maxBd,
    minBa,
    maxBa,
    listingServices = []
  } = property
  const rentable = availableStatus !== 2
  const matches = useMediaQuery('@media (min-width:768px)')
  const { favor, requestFavor } = usePropertyFavor(id, !!isFavor)
  const currentPropertyDetailPageUrl = useMemo(
    () => getDetailPageUrlPath(city, area, name, id),
    [city, area, name, id]
  )

  const handClickCard = e => {
    e.stopPropagation()
    e.preventDefault()
    handleClick(property)
  }

  const onFavor = async e => {
    e.stopPropagation()
    e.preventDefault()
    await requestFavor(id, !favor, !matches)
    afterFavor({ ...property, isFavor: !favor })
  }
  const rewards = useMemo(() => [10000083, 10000084, 10019976].includes(Number(id)), [id])
    ? 250
    : 200
  const isShareActivity = listingServices?.indexOf(LISTING_SERVICE.TRIPALINK) !== -1
  const onShare = () => {
    // for statsig
    statsigLog('property_share_click', {
      property_id: id
    })

    const { host, protocol } = window.location
    const currentShareUrl = `${protocol}//${host}${getDetailPageUrlPath(city, area, name, id)}`
    shareConfig({
      open: true,
      otherUrl: currentShareUrl,
      isShareActivity,
      rewards
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } as any)
  }

  const handleSlideChange = () => {
    // for statsig
    statsigLog('property_picture_click', {
      property_id: id
    })
  }

  const isSqrt = ((minBd || maxBd) && ~~minBd < 500) || ((minBa || maxBa) && ~~minBa < 500)

  return (
    <Box
      className={`${className} relative font-averta rounded-2xl block bg-white mx-auto text-primary border border-[#ddd] cursor-pointer`}
      key={id}
      onMouseEnter={e => {
        e.nativeEvent.preventDefault()
        e.nativeEvent.stopImmediatePropagation()
        if (onMouseEnter) {
          onMouseEnter(property)
        }
      }}
      onMouseLeave={e => {
        e.nativeEvent.preventDefault()
        e.nativeEvent.stopImmediatePropagation()
        if (onMouseLeave) {
          onMouseLeave(property)
        }
      }}
    >
      <Link
        className="absolute -z-10 inset-0 opacity-0"
        target="_blank"
        onClick={e => e.preventDefault()}
        href={currentPropertyDetailPageUrl}
      >
        {name}
      </Link>
      <Box className="relative p-2">
        <Swiper
          className={`location-swiper rounded-xl ${isMapMode ? 'h-[134px]' : 'h-[184px]'}`}
          navigation
          allowTouchMove={allowTouchPicMove}
          modules={[Navigation]}
          preventClicks={false}
          slidesPerView="auto"
          onActiveIndexChange={handleSlideChange}
        >
          {(picList || []).map(
            ({ url, tag }, index) =>
              index < 10 && (
                <SwiperSlide style={{ height: '100%' }} key={[index, '-'].join('-')}>
                  {!rentable && (
                    <Box
                      onClick={handClickCard}
                      className="absolute top-0 left-0 right-0 rounded-xl bg-black-500 z-10 w-full h-full flex flex-row justify-center items-center text-white text-2xl font-bold"
                    >
                      <Box>Rented Out</Box>
                    </Box>
                  )}
                  <LazyImg
                    url={url}
                    size={400}
                    priority={preLoadImg && index === 0 && cardIndex <= 2}
                    title={address}
                    alt={tag || address}
                    style={{ objectFit: 'cover' }}
                    className="object-fill"
                    onClick={handClickCard}
                  />
                </SwiperSlide>
              )
          )}
        </Swiper>
        {marketingTag ? (
          <Box className="flex flex-row items-center bg-gradient-to-r from-yellow-light to-yellow-light pl-2 pr-3 py-1 rounded-middling absolute left-4 bottom-4 z-10">
            {tagIconList.map(
              ({ title, url }) =>
                title === marketingTag && (
                  <Image key={title} src={url} width={12} height={12} alt="icon" />
                )
            )}
            <Typography
              variant="body1"
              component="div"
              className="text-primary ml-1.5 text-xs leading-4 font-normal"
            >
              {marketingTag}
            </Typography>
          </Box>
        ) : null}
        <Box className="flex flex-row items-center absolute top-4 left-4" onClick={handClickCard}>
          {isVr ? (
            <Box className="bg-white rounded-2xl text-xs text-black-dark z-10 px-2 py-1 mr-1.5">
              Virtual Tour
            </Box>
          ) : null}
          {isVideo ? (
            <Box className="bg-white rounded-2xl text-xs text-black-dark z-10 px-2 py-1">Video</Box>
          ) : null}
        </Box>
        <Box className="flex flex-row items-center gap-2 absolute top-4 right-4 z-10">
          <Box
            onClick={e => {
              e.nativeEvent.stopImmediatePropagation()
              e.nativeEvent.stopPropagation()
              e.nativeEvent.preventDefault()
              onShare()
            }}
            className={`flex items-center justify-center ${
              isMapMode ? 'w-7 min-w-7 h-7' : 'w-9 min-w-9 h-9'
            } p-0 bg-black-dark/40 rounded-full`}
          >
            <Share className={`${isMapMode ? 'w-3' : 'w-4'}`} color="#fff" />
          </Box>
          {!hiddenFavor && (
            <Box
              // onClick={}
              className={`flex items-center justify-center ${
                isMapMode ? 'w-7 min-w-7 h-7' : 'w-9 min-w-9 h-9'
              } p-0 bg-black-dark/40 rounded-full`}
            >
              <FavoriteIcon
                onClick={onFavor}
                favor={favor}
                className="w-5 h-5 cursor-pointer animate-[favor_0s] active:animate-none hover:animate-[favor_0.5s] "
              />
            </Box>
          )}
        </Box>
      </Box>
      <Box
        className={`${rentable ? '' : 'opacity-50'} pl-5 pr-3 pb-3 pt-2`}
        onClick={handClickCard}
      >
        <Box component="div" className="flex justify-between items-center">
          <Box className="text-primary flex items-baseline">
            <Typography component="div" className="text-2xl leading-[1] font-bold font-minion">
              {rentable && minPrice !== '' && isFinite(parseFloat(minPrice))
                ? `$${getCurrency(minPrice)}`
                : 'Rented out'}
            </Typography>
            <Typography variant="body1" component="div" className="text-base">
              {rentable && minPrice !== '' && isFinite(parseFloat(minPrice)) ? '/mo' : ''}
            </Typography>
          </Box>
          <Tooltip
            title="This Listing has been verified by Renty.AI’s listing verification process, which combines internal screening and fraud detection technology."
            arrow
            classes={{ tooltip: 'text-xs font-normal py-3 px-4 rounded-xl font-averta' }}
          >
            <Box component="div" className="flex items-center gap-1 cursor-pointer w-fit">
              <Verify className="w-4 h-4" />
              <Box component="div" className="text-xs text-primary">
                Verified Listing
              </Box>
            </Box>
          </Tooltip>
        </Box>

        <Box
          component="div"
          className={`${isMapMode ? 'text-xs' : 'text-sm'} flex items-center mt-2`}
          sx={{ display: isSqrt ? 'flex' : 'none' }}
        >
          {/* 当没有unit时，propery的bed和bath数量异常兼容处理 */}
          {(minBd || maxBd) && ~~minBd < 500 ? (
            <Box
              className={`${
                isMapMode ? 'px-1' : 'px-2'
              } flex items-center mr-2 py-1 rounded-lg bg-gray-100`}
            >
              <Bed color="#717171" className={isMapMode ? 'text-sm' : 'text-base'} />
              <span className="ml-1 flex-shrink-0">
                {minBd === maxBd
                  ? `${minBd === 0 ? 'Studio' : `${minBd} Beds`}`
                  : `${minBd === 0 ? 'Studio' : minBd} - ${maxBd} Beds`}
              </span>
            </Box>
          ) : null}
          {/* 当没有unit时，propery的bed和bath数量异常兼容处理 */}
          {(minBa || maxBa) && ~~minBa < 500 ? (
            <Box
              className={`${
                isMapMode ? 'px-1' : 'px-2'
              } flex items-center py-1 rounded-lg bg-gray-100`}
            >
              <Bath color="#717171" className={isMapMode ? 'text-sm' : 'text-base'} />
              <span className="ml-1 flex-shrink-0">
                {minBa === maxBa ? minBa : `${minBa} - ${maxBa}`} Baths
              </span>
            </Box>
          ) : null}
        </Box>

        <Box className="flex flex-row items-center mt-2">
          <Location className="w-3 h-3 mr-1" color="#B0B0B0" />
          <Typography
            variant="body1"
            component="h2"
            title={name || address}
            className={`overflow-hidden text-ellipsis whitespace-nowrap text-left ${
              isMapMode ? 'text-xs' : 'text-sm'
            } w-full leading-4`}
          >
            {name || address}
          </Typography>
        </Box>

        {!isSqrt ? (
          <Box className={`${isMapMode ? 'px-1 text-xs' : 'px-2 text-sm'} py-1 opacity-0 mt-2`}>
            sqrt
          </Box>
        ) : null}

        <Box className="flex flex-row items-baseline mt-2">
          <Typography
            variant="body1"
            component="div"
            className={`${
              availableContent.toLocaleLowerCase() === 'available now'
                ? 'text-blue'
                : 'text-secondary'
            } text-left ${
              isMapMode ? 'text-xs' : 'text-sm'
            } overflow-hidden text-ellipsis whitespace-nowrap font-normal`}
          >
            {availableContent}
          </Typography>
        </Box>

        {/* <Box className="flex flex-row items-center justify-between mt-2">
          <IconButton className="bg-[#F7F7F7] w-8 h-8" onClick={handleClickSearch}>
            <SearchIcon className="text-base leading-[1]" />
          </IconButton>
        </Box> */}
      </Box>
    </Box>
  )
}

export default HouseCard
