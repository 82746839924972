const InfoBoxData = [
  {
    id: 0,
    url: `${process.env.CDN_PREFIX}/web/43baa36d6b27352a.png`,
    isCollection: false,
    status: 1,
    type: 0,
    name: 'Gemma South',
    address: 'Gemma South',
    rentType: 'COLIVING',
    totalBD: 10,
    amenties: [{ type: 'Wifi' }, { type: 'Kitchen' }, { type: 'Heating' }, { type: 'POOL' }],
    tag: 'Co-living',
    tags: ['2-3 beds', '2.5 bath', '1.898 sq ft'],
    date: '08.10.22',
    price: '2500',
    isUnits: false,
    isAdvantage: false,
    isAdvertisement: false,
    tagIcon: `${process.env.CDN_PREFIX}/web/1213d7bab57fd3ef.png`,
    img: [
      `${process.env.CDN_PREFIX}/web/ec51ea7dd25671db.png`,
      `${process.env.CDN_PREFIX}/web/43baa36d6b27352a.png`
    ]
  }
]

const mobileInfoBoxData = [
  {
    id: 0,
    url: `${process.env.CDN_PREFIX}/web/43baa36d6b27352a.png`,
    isCollection: false,
    status: 1,
    type: 0,
    name: 'Gemma South',
    address: 'Gemma South',
    rentType: 'COLIVING',
    totalBD: 10,
    amenties: [{ type: 'Wifi' }, { type: 'Kitchen' }],
    tag: 'Co-living',
    tags: ['2-3 beds', '2.5 bath', '1.898 sq ft'],
    date: '08.10.22',
    price: '2500',
    tagIcon: `${process.env.CDN_PREFIX}/web/1213d7bab57fd3ef.png`,
    img: `${process.env.CDN_PREFIX}/web/2b6614645df1e8df.png`,
    icon: `${process.env.CDN_PREFIX}/web/1213d7bab57fd3ef.png`
  }
]

const listingData = [
  {
    id: 0,
    img: `${process.env.CDN_PREFIX}/web/43baa36d6b27352a.png`,
    isCollection: false,
    status: 1,
    type: 0,
    address: 'Gemma South',
    tag: 'Co-living',
    date: '08.10.22',
    price: '2500',
    isUnits: false,
    urlPathName: 'los-angeles/usc-off-campus-student-housing',
    amenties: [{ type: 'Wifi' }, { type: 'Kitchen' }, { type: 'Heating' }, { type: 'POOL' }],
    rentType: 'COLIVING',
    totalBD: 10,
    message: 'Special offer',
    icon: `${process.env.CDN_PREFIX}/web/d0156bb8605dea6c.png`
  },
  {
    id: 1,
    img: `${process.env.CDN_PREFIX}/web/43baa36d6b27352a.png`,
    isCollection: false,
    status: 2,
    type: 1,
    address: 'The Harper Building',
    tag: 'Co-living',
    date: '08.10.22',
    price: '2100',
    isUnits: false,
    urlPathName: 'los-angeles/usc-off-campus-student-housing',
    amenties: [{ type: 'Wifi' }, { type: 'Kitchen' }, { type: 'Heating' }, { type: 'POOL' }],
    rentType: 'COLIVING',
    totalBD: 10,
    message: 'Luxury unit',
    icon: `${process.env.CDN_PREFIX}/web/1213d7bab57fd3ef.png`
  },
  {
    id: 2,
    img: `${process.env.CDN_PREFIX}/web/43baa36d6b27352a.png`,
    isCollection: true,
    status: 3,
    type: 0,
    address: '4749 Elmwood Ave',
    tag: 'Building',
    date: '08.10.22',
    price: '2400',
    isUnits: true,
    urlPathName: 'los-angeles/usc-off-campus-student-housing',
    amenties: [{ type: 'Wifi' }, { type: 'Kitchen' }, { type: 'Heating' }, { type: 'POOL' }],
    rentType: 'COLIVING',
    totalBD: 101,
    message: 'Hot Deal',
    icon: `${process.env.CDN_PREFIX}/web/7264aaf6730e6efa.png`
  },
  {
    id: 3,
    img: `${process.env.CDN_PREFIX}/web/43baa36d6b27352a.png`,
    isCollection: false,
    status: 3,
    type: 1,
    address: 'The Harper Building',
    tag: 'Co-living',
    date: '08.10.22',
    price: '2100',
    isUnits: false,
    urlPathName: 'los-angeles/usc-off-campus-student-housing',
    amenties: [{ type: 'Wifi' }, { type: 'Kitchen' }, { type: 'Heating' }, { type: 'POOL' }],
    rentType: 'COLIVING',
    totalBD: 10,
    message: 'Luxury unit',
    icon: `${process.env.CDN_PREFIX}/web/1213d7bab57fd3ef.png`
  },
  {
    id: 4,
    img: `${process.env.CDN_PREFIX}/web/43baa36d6b27352a.png`,
    isCollection: true,
    status: 2,
    type: 0,
    address: '4749 Elmwood Ave',
    tag: 'Building',
    date: '08.10.22',
    price: '2400',
    isUnits: true,
    urlPathName: 'los-angeles/usc-off-campus-student-housing',
    amenties: [{ type: 'Wifi' }, { type: 'Kitchen' }, { type: 'Heating' }, { type: 'POOL' }],
    rentType: 'COLIVING',
    totalBD: 101,
    message: 'Hot Deal',
    icon: `${process.env.CDN_PREFIX}/web/7264aaf6730e6efa.png`
  }
]

const tagIconList = [
  {
    title: 'Special Offer',
    url: `${process.env.CDN_PREFIX}/web/61f95fbc6a63f925.png`
  },
  {
    title: 'Hot',
    url: `${process.env.CDN_PREFIX}/web/7264aaf6730e6efa.png`
  },
  {
    title: 'Pre-leasing',
    url: `${process.env.CDN_PREFIX}/web/815d22a29afbde09.png`
  },
  {
    title: 'Free Parking',
    url: `${process.env.CDN_PREFIX}/web/2b8b37bb02483f52.png`
  },
  {
    title: 'Last Room',
    url: `${process.env.CDN_PREFIX}/web/22760703a46c510e.png`
  },
  {
    title: 'Short Term Available',
    url: `${process.env.CDN_PREFIX}/web/58cb4ad6229a0703.png`
  },
  {
    title: 'New',
    url: `${process.env.CDN_PREFIX}/web/28e0afe68e1cb5e6.png`
  },
  {
    title: 'Open House',
    url: `${process.env.CDN_PREFIX}/web/5145d1905ba04dea.png`
  }
]

const tabList = [
  {
    id: 10,
    name: 'Private Bath',
    check: false,
    key: 'isPrivateBathroom'
  },
  {
    id: 12,
    name: 'Furnished',
    check: false,
    key: 'isFurnished'
  },
  {
    id: 11,
    name: 'Parking',
    check: false,
    key: 'isParking'
  },

  {
    id: 14,
    name: 'In-Unit W/D',
    check: false,
    key: 'isInUnitLaundry'
  },
  {
    id: 13,
    name: 'Pet-friendly',
    check: false,
    key: 'isPets'
  },
  {
    id: 15,
    name: 'AC',
    check: false,
    key: 'isAC'
  }
]

const bedsList = [
  {
    title: 'Any',
    value: ''
  },
  {
    title: 'Studio',
    value: 0
  },
  {
    title: '1',
    value: 1
  },
  {
    title: '2',
    value: 2
  },
  {
    title: '3',
    value: 3
  },
  {
    title: '4',
    value: 4
  },
  {
    title: '5',
    value: 5
  },
  {
    title: '6+',
    value: 6
  }
]

const cityIntroduction = [
  {
    cityName: 'Los Angeles',
    content: ''
  },
  {
    cityName: 'Pittsburgh',
    content:
      '<p>Known as “the Steel City”, Pittsburgh is an industrial, cultural, and economic hub of the nation. Featuring numerous skyscrapers and railways, the city is rich with preserved architecture, public sculptures, and grand buildings. The city also has countless large public parks, including ‘The Point’, a state park located at the confluence of the Allegheny and Monongahela rivers that forms the Ohio River. Divided into Downtown, the North Side, and the South Side, Pittsburgh has numerous attractions over the city. Head to the North Side to find Heinz Field and PNC Park, the South Side for the city’s steel mills, and Downtown for the majority of the city’s entertainment, restaurants, and nightlife. “The Golden Triangle” of Downtown is also a young and lively environment, with students from the University of Pittsburgh, Carnegie Mellon University, Chatham University, and more within proximity of the neighborhood.</p>'
  },
  {
    cityName: 'Seattle',
    content:
      '<p>Situated between Puget Sound and Lake Washington, Seattle is the metropolitan center of the Pacific Northwest. Beautiful mountain ranges including Mount Rainier, Mount St. Helens, and Mount Adams make Seattle one of the most picturesque cities in the country and a dream destination for nature buffs. Despite the abundance of greenery, Seattle is still a vital city center, with Fortune 500 Giants including Amazon, Microsoft, Nordstrom, and Starbucks just to name a few that have headquarters in the Pacific Northwest. With the University of Washington and Seattle University, the city is filled with students and young professionals ready to take on all of the vibrant art scene, dining and restaurant experience, outdoor exploration, and more that Seattle has to offer.</p>'
  },
  {
    cityName: 'Philadelphia',
    content:
      '<p>Once briefly serving as the nation’s capital, Philadelphia is one of the most historical cities in the U.S. Situated along the banks of the Delaware River, Pennsylvania’s largest city is one of the liveliest on the East Coast. Philly’s rich historical context meets today’s modernity to create a unique city experience for its residents. Learn the history behind the preserved Liberty Bell and at the Philadelphia Museum of Art, or explore Philadelphia’s Fairmount Park and metropolitan skyline.</p>'
  },
  {
    cityName: 'Irvine',
    content:
      '<p>Within the Los Angeles Metropolitan area and right in the heart of Orange County sits the city of Irvine, one of the newest and well-planned cities in the area. From its formation, Irvine is composed of a distinct city layout that divides land into distinct villages with similar designs in houses, commercial centers, schools, and more. With proximity to the iconic Irvine Spectrum Center, gorgeous beach towns such as Newport Beach or Laguna Beach, and nature preserves like the Orange County Great Park, Irvine is one of the most idyllic cities in OC and a great place to live for students and young professionals.</p>'
  },
  {
    cityName: 'Tucson',
    content:
      '<p>Nestled in Arizona’s Sonoran Desert is the city of Tucson. Surrounded by numerous mountain ranges and nature preserves, such as Saguaro National Park and Tucson Mountain Park, Tucson is not only scenic but the perfect place for residents who enjoy the outdoors. It is also the second-largest city in Arizona and situated just 60 miles north of the U.S.-Mexico border. With more than 45,000 students enrolled at the University of Arizona and countless alumni in the area, young professionals make a significant contribution to Downtown Tucson’s entertainment, outdoors, restaurant, and nightlife scenes.</p>'
  },
  {
    cityName: 'Tacoma',
    content:
      '<p>As the smaller sister counterpart to Northern Seattle, Tacoma, WA, has plenty of features and options to offer. There are plenty of reasons to look for studio apartments for rent near Tacoma! This port city sits at the bottom of the beautiful Puget Sound and is just 32 miles southwest of Seattle. Tacoma is 31 miles North of the state capital, Olympia, and about 60 miles northwest of Mount Rainier National Park. Tacoma, WA, is also just 20 minutes from the Seattle-Tacoma International Airport (Sea-Tac), making it a convenient place to live for those who need or like to travel.</p>'
  },
  {
    cityName: 'Chicago',
    content: ''
  }
]

export {
  InfoBoxData,
  mobileInfoBoxData,
  listingData,
  tagIconList,
  tabList,
  bedsList,
  cityIntroduction
}
